import React, { useEffect, useRef } from 'react'
import styles from './index.module.scss'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import InlineVideo from 'components/inline-video'
import MainSlideshow from 'components/main-slideshow'
import ProductBlock from 'components/product-block'
import LoopScroll from 'components/loop-scroll'
import Sticky from 'components/react-stickynode'
import { sluggy } from '@/utils'
import animateScrollTo from 'animated-scroll-to'
import { Link } from 'gatsby-plugin-modal-routing'
import { useWindowWidth } from '@react-hook/window-size'
import useIsMobile from '@/utils/useIsMobile'
import { pauseHomeSlideshowState, isIntroState } from 'state'
import { useSetRecoilState } from 'recoil'
import Constants from "constants"

const HomePage = (props) => {
  const parent = useRef()
  const easing = t => 1 + (--t) * t * t * t * t
  const { location, pageContext } = props
  const lang = props.pageContext.code
  const windowW = useWindowWidth()
  const isMobile = useIsMobile()
  const slideshowData = props.data.contentfulMainSlideshow.slides
  const pauseSlideshow = useSetRecoilState(pauseHomeSlideshowState)
  const setIsIntroState = useSetRecoilState(isIntroState)
  const getMobileRow = (i, len) => {
    const ratio = i % 3
    if (ratio === 0) return 1
    if (ratio === 1 && i > len-2) return 1
    else return 2
  }
  const allCats = props.data.allContentfulCategory.nodes.filter(cat => cat.product !== null).map((cat, i) => {
    const len = cat.product.length
    const slugCategory = sluggy(cat.title)
    const flow = () => {
      if (len === 1) return `justify-center`
      else if (len === 2) return `flex-row-reverse`
      else return ``
    }
    const blocks = cat.product.sort((a,b) => parseInt(a.order) - parseInt(b.order)).map((block, i) => {
      const slugTitle = sluggy(block.title)
      const url = `/${pageContext.code}/product/${slugTitle}/`
      return (
        <Link
          key={block.id}
          to={url}
          className={`${styles.productBlock} relative product-block h-32`}
          data-index={i}
          data-category-length={cat.product.length}
          asModal
        >
          <ProductBlock hero={block.heroImage} />
          <div className={`${styles.productTitle} absolute z-10 text-sm lg:text-base p-4 pl-5 lg:pl-6 bottom-0`}>{ block.title }</div>
        </Link>
      )
    })
    return (
      <div className={slugCategory} key={cat.id}>
        <div className={`w-full`}>
          <Sticky
            enabled={true}
            bottomBoundary={`#title-bottom-${i}`}
            innerZ={40}
          >
            <div className={`z-10 absolute h-20 category-title`}>
              <div style={{ marginLeft: 1 }} className={`p-4 pl-5 uppercase text-sm lg:text-base`}>{cat.title}</div>
            </div>
          </Sticky>
          <div className={`flex content-start flex-wrap ${flow()}`}>{ blocks }</div>
          <div id={`title-bottom-${i}`} className={`block`}></div>
        </div>
      </div>
    )
  })
  const onTransition = (e) => pauseSlideshow(e)
  const removeHash = () => {
    window.history.pushState("", document.title, window.location.pathname + window.location.search)
  }
  useEffect(() => {
    const el = parent.current.parent.current
    const productBlocks = [...el.getElementsByClassName('product-block')]
    const categoryTitles = [...el.getElementsByClassName('category-title')]
    categoryTitles.forEach(item => {
      item.style.left = isMobile ? `0%` : `57.1428571%`
    })
    productBlocks.forEach(item => {
      const numRow = isMobile ? getMobileRow(parseInt(item.getAttribute('data-index')), parseInt(item.getAttribute('data-category-length'))) : 3
      const w = windowW / numRow
      const h = w * 1.6
      item.style.width = `${w}px`
      item.style.height = `${h}px`
    })
  })
  useEffect(() => {
    if (window.location.hash) {
      const hashId = window.location.hash.substr(1)
      const el = parent.current.parent.current.getElementsByClassName(hashId)[0]
      animateScrollTo(el, { speed: 1000, easing, cancelOnUserAction: false }).then(() => {
        setTimeout(() => {
          removeHash()
        }, 500)
      })
    }
  })
  const onSlideshowClick = () => {
    if (window.innerWidth < 1024) return
    const el = parent.current.parent.current.getElementsByClassName(`anchor`)[0]
    animateScrollTo(el, { speed: 1000, easing, cancelOnUserAction: false })
    setIsIntroState(true)
  }
  return (
    <Layout>
      <SEO
        title={`Home`}
        lang={pageContext.code}
        pathname={location.pathname}
      />
      <LoopScroll onTransition={onTransition} fixedTargetIndex={1} ref={parent} className={`${styles.parent} home-page`}>
        <Sticky
          enabled={true}
          bottomBoundary='#logo-bottom'
          innerZ={20}
        >
          <Link className={`lg:hidden absolute z-30 p-4 pl-logo`} to={`/${lang}`}>
            <img alt={`Pierre Augustin Rose`} src={`/logo.svg`} />
          </Link>
        </Sticky>
        <div className={`w-full hello`}>
          { typeof window !== `undefined` && window.innerWidth < Constants.MQ_LARGE && <MainSlideshow onClick={onSlideshowClick} className={`w-full relative block lg:hidden h-80vh overflow-hidden`} slides={slideshowData} />}
          { typeof window !== `undefined` && window.innerWidth > Constants.MQ_LARGE &&
          <div className={`w-full h-60vh lg:h-screen hidden lg:block overflow-hidden`}>
            <InlineVideo
              onClick={onSlideshowClick}
              className={`w-full h-full block`}
              videoStyle={{objectFit: 'cover'}}
              srcDesktop={`/PAR_8RH_desktop.mp4`}
              srcMobile={`/PAR_8RH_mobile.mp4`}
            />
          </div>
          }
        </div>
        <div id={`logo-bottom`} className={`block lg:hidden`}></div>
        <div className={`anchor`}></div>
        <div className={`relative`}>{ allCats }</div>
      </LoopScroll>
    </Layout>
  )
}

export const ProductsQuery = graphql`
  query HomeProductsQuery($code: String) {
    allContentfulCategory(
      filter: {node_locale: {eq: $code}}
      sort: {
        fields: [order]
        order: ASC
      }
    ) {
      nodes {
        id
        title
        product {
          id
          title
          order
          heroImage {
            fluid(maxWidth: 1024, quality: 65) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    contentfulMainSlideshow {
      slides {
        id
        appearance
        desktop {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        mobile {
          fluid(maxWidth: 1600, quality: 70) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default HomePage
